import styles from './EServices.module.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const Tracking = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, [])
  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <div className={`${styles.homeButtonWrapper}`}>
            <a href={'/'} className={`${styles.homeButton}`}>
              {t('Home')}
            </a>
            <div className={`${styles.arrow}`}>{'>'}</div>
            <a
              href={'/e-services'}
              className={`${styles.homeButton}`}>
              {t('Back to e-services')}
            </a>
          </div>
          <h2 className={`${styles.hTwoTitlePage}`}>{t("Container Tracking")}</h2>
          <div className={styles.eServiceContent}>

            <div className={styles.serviceIntro}>
              <p>{t("E-service which allows beneficiaries to find the current location of containers on Google Maps, and to get information on the containers’ status from the port of export to the port of import, while it also allows beneficiaries to know the time required for the arrival of goods.")}</p>
              <div className="pt-5 pb-3">
                <a className="button-default" href="https://www.fasah.sa/sanam/sau/SAUTrackingServlet?action_=trackingHome" target="_blank">
                  {t("Access to the service")}
                </a>
                {/* <span className="font-bold text-same flex justify-center">
        To Access service click&nbsp;<a
          href="https://www.fasah.sa/sanam/sau/SAUTrackingServlet?action_=trackingHome"
          target="_blank"
          className="flex justify-center font-normal underline text-same text-[#2D6A80]"> here </a>
        </span> */}


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tracking;

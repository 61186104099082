import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import KeepMeInformed from 'components/home/KeepMeInformed';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import LicenseComp from 'components/EServices/License';
import PopUpEServices from 'components/EServices/PopUpEServices';

export default function EservicesLicense() {
  return (
    <>
      <Navbar />
      {/* <PopUpEServices /> */}
      <LicenseComp />
      {/* <KeepMeInformed /> */}
      <Information />
      <Subscribe />
      <DefaultFooter />
    </>
  );
}

import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Landing from 'pages/Landing/index.js';
// import Profile from 'pages/Profile';
// import Login from 'pages/Login';
// import Register from 'pages/Register';

// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';
// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css';
import 'assets/styles/index.css';
import Home from 'pages/Home';
import AboutUs from 'pages/AboutUs';
import Ports from 'pages/Ports';
import DoingBusiness from 'pages/DoingBusiness';
import SafetySecurity from 'pages/SafetySecurity';
import EServices from 'pages/EServices';
import Biddings from 'pages/EServices/Biddings';
import VesselSearch from 'pages/EServices/VesselSearch';
import EservicesLicense from 'pages/EServices/License';
import Tracking from 'pages/EServices/Tracking';
import Invoice from 'pages/EServices/Invoice';
import RentalFacilities from 'pages/EServices/RentalFacilities';
import TransactionTrackingService from 'pages/EServices/TransactionTrackingService';
import Statistics from 'pages/EServices/Statistics';
import Order from 'pages/EServices/Order';
import SLAComponentPage from 'pages/EServices/SLAComponent';
import ChatPage from 'pages/EServices/Chat';
import ElectronicPaymentService from 'pages/EServices/ElectronicPaymentService';
import NewInvoice from 'pages/EServices/NewInvoice';
import Regulations from 'pages/Regulations';
import Article from 'pages/Article';
import History from 'pages/History';
import ContactUs from 'pages/ContactUs';
import Circulars from 'pages/Circulars';
import Jobs from 'pages/Jobs';
import PortEmployees from 'pages/PortEmployees';
import NotFound from 'pages/NotFound';
import Tools from 'pages/Tools';
import DiscoverSeaServics from 'pages/DiscoverSeaService';
import MediaCenter from 'pages/MediaCenter';
// import ServicesAndCapabilities from 'pages/ServicesAndCapabilities';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Policy from 'pages/Policy';
import Conditions from 'pages/Conditions';
import Customer from 'pages/Customer';
import Announcement from 'pages/Announcement';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getURLQuery } from 'utils/urlHelper';
import { useLanguage } from 'hooks/useLanguage';
import SafeUsage from "pages/SafeUsage"
import ReactGA from 'react-ga4';
import { GA_TAG } from 'utils/constants';
const queryClient = new QueryClient();
const routes = [
  {
    path: '/',
    name: {
      en: '',
      ar: '',
    },
    component: Home,
  },
  {
    path: '/home',
    name: {
      en: '',
      ar: '',
    },
    component: Home,
  },
  {
    path: '/about-us',
    name: {
      en: 'About Us',
      ar: 'معلومات عنا',
    },
    component: AboutUs,
  },
  {
    path: '/ports',
    name: {
      en: 'Ports',
      ar: 'الموانئ',
    },
    component: Ports,
  },
  // {
  //   path: '/sea-services',
  //   name: {
  //     en: '',
  //     ar: '',
  //   },
  //   component: DiscoverSeaServics,
  // },
  {
    path: '/media-center',
    name: {
      en: 'Media Center',
      ar: 'المركز الاعلامي',
    },
    component: MediaCenter,
  },
  {
    path: '/article',
    name: {
      en: 'Article',
      ar: 'مقال',
    },
    component: Article,
  },
  {
    path: '/history',
    name: {
      en: 'History',
      ar: 'تاريخ',
    },
    component: History,
  },
  // {
  //   path: '/doing-business',
  //   name: {
  //     en: '',
  //     ar: '',
  //   },
  //   component: DoingBusiness,
  // },
  // {
  //   path: '/safety-security',
  //   name: {
  //     en: '',
  //     ar: '',
  //   },
  //   component: SafetySecurity,
  // },
  {
    path: '/e-services',
    name: {
      en: 'E-Services',
      ar: 'خدمات موانئ',
    },
    component: EServices,
  },
  {
    path: '/e-services/biddings',
    name: {
      en: 'Biddings',
      ar: 'المنافسات والمزادات',
    },
    component: Biddings,
  },
  {
    path: '/e-services/vesselsearch',
    name: {
      en: 'Vessel Search',
      ar: 'مواعيد السفن',
    },
    component: VesselSearch,
  },
  {
    path: '/e-services/license',
    name: {
      en: 'License',
      ar: 'التراخيص',
    },
    component: EservicesLicense,
  },
  {
    path: '/e-services/containertracking',
    name: {
      en: 'Container Tracking',
      ar: 'تتبع الحاويات',
    },
    component: Tracking,
  },
  {
    path: '/e-services/iogsx',
    name: {
      en: 'Invoices of Owners of Goods Service',
      ar: 'خدمة فواتير أصحاب البضائع',
    },
    component: Invoice,
  },
  {
    path: '/e-services/reqrentalfacilities',
    name: {
      en: 'Request for Rental of Facilities',
      ar: 'طلب استئجار مرافق',
    },
    component: RentalFacilities,
  },
  {
    path: '/e-services/trxtracking',
    name: {
      en: 'Transaction Tracking Service',
      ar: 'خدمة تتبع المعاملات',
    },
    component: TransactionTrackingService,
  },
  {
    path: '/e-services/statistics',
    name: {
      en: 'Statistics',
      ar: 'إحصائيات',
    },
    component: Statistics,
  },
  {
    path: '/e-services/moveableitems',
    name: {
      en: 'Orders',
      ar: 'منصة المنقولات',
    },
    component: Order,
  },
  {
    path: '/e-services/sla',
    name: {
      en: 'SLA',
      ar: 'اتفاقية مستوى تقديم الخدمة للمستفيدين (SLA)',
    },
    component: SLAComponentPage,
  },
  {
    path: '/e-services/tktfollowup',
    name: {
      en: 'Chat',
      ar: 'تتبع التذكرة',
    },
    component: ChatPage,
  },
  {
    path: '/e-services/epayment',
    name: {
      en: 'Electronic payment service',
      ar: 'خدمة الدفع الإلكترونية',
    },
    component: ElectronicPaymentService,
  },
  {
    path: '/e-services/iogs',
    name: {
      en: 'New version of Invoices of Owners of Goods Service "Trial Version"',
      ar: 'خدمة فواتير أصحاب البضائع الاصدار الجديد "نسخة تجريبية"',
    },
    component: NewInvoice,
  },
  {
    path: '/regulations',
    name: {
      en: 'Regulations',
      ar: 'اللوائح',
    },
    component: Regulations,
  },
  // {
  //   path: '/services-and-capabilities',
  //   name: {
  //     en: '',
  //     ar: '',
  //   },
  //   component: ServicesAndCapabilities,
  // },
  {
    path: '/vacancies',
    name: {
      en: 'Jobs',
      ar: 'وظائف',
    },
    component: Jobs,
  },
  {
    path: '/port-employees',
    name: {
      en: 'Ports Employees',
      ar: 'منسوبي الهيئة',
    },
    component: PortEmployees,
  },
  {
    path: '/contact',
    name: {
      en: 'Contact Us',
      ar: 'اتصل بنا',
    },
    component: ContactUs,
  },
  // {
  //   path: '/tools',
  //   name: {
  //     en: '',
  //     ar: '',
  //   },
  //   component: Tools,
  // },
  // {
  //   path: '/profile',
  //   name: {
  //     en: '',
  //     ar: '',
  //   },
  //   component: Profile,
  // },
  // {
  //   path: '/login',
  //   name: {
  //     en: '',
  //     ar: '',
  //   },
  //   component: Login,
  // },
  // {
  //   path: '/register',
  //   name: {
  //     en: '',
  //     ar: '',
  //   },
  //   component: Register,
  // },
  {
    path: '/circulars',
    name: {
      en: 'Circulars',
      ar: 'تعاميم',
    },
    component: Circulars,
  },
  {
    path: '/privacy-policy',
    name: {
      en: 'Policy',
      ar: 'سياسة الخصوصية',
    },
    component: Policy,
  },
  {
    path: '/404',
    name: {
      en: 'Error...',
      ar: 'خطأ',
    },
    component: NotFound,
  },
  {
    path: '/conditions',
    name: {
      en: 'Conditions',
      ar: 'شروط وأحكام استخدام موقع الإنترنت',
    },
    component: Conditions,
  },
  {
    path: '/customerportal',
    name: {},
    component: Customer,
  }
  ,
  {
    path: '/announcement',
    name: {},
    component: Announcement,
  },
  {
    path: "/safe-usage",
    name: {
      en: 'Safe Usage and Privacy Policy',
      ar: 'سياسة الاستخدام الآمن والخصوصية',
    },
    component: SafeUsage,
  }
];

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();

  ReactGA.initialize(GA_TAG);

  useEffect(() => {
    const siteName =
      i18n.language === 'en'
        ? 'Saudi Ports Authority'
        : 'الهيئة العامة للموانئ';

    const pageName = routes.find((i) => i.path === location.pathname)?.name[
      i18n.language
    ];
    document.title = `${siteName}${pageName ? ` - ${pageName}` : ''}`;
  }, [i18n.language, location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useLanguage();

  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        {routes.map((i, index) => (
          <Route exact path={i.path} m key={index} component={i.component} />
        ))}
        <Redirect from="*" to="/" />
      </Switch>
    </QueryClientProvider>
  );
}

export default App;

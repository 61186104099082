import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { t } from 'i18next';
import { Alert } from '@mui/material';

import styles from './styles.module.scss';
import { DebouncedInput } from './DebouncedInput';

const PAGE_SIZES = [10, 25, 50, 100];

export const Table = ({ data, columns }) => {
  return (
    <>
      {data && data.length > 0 ? (
        <TableComponent data={data} columns={columns} />
      ) : (
        <div className="py-4">
          <Alert severity="info">{t('No data available')}</Alert>
        </div>
      )}
    </>
  );
};

const TableComponent = ({ data, columns }) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [remountComponent, setRemountComponent] = useState(0);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: 'includesString',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const currentPageSize = table.getState().pagination.pageSize;
  const totalPages = table.getPageCount();

  useEffect(() => {
    // remount react-paginate to reset selected page
    setRemountComponent(Math.random());
  }, [globalFilter, currentPageSize]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <span>{t('Display')}</span>
          <select
            value={currentPageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="shadow border p-2">
            {PAGE_SIZES.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <span>{t('records per page')}</span>
        </div>
        <div className="flex gap-2 items-center">
          <label>{t('Search')}:</label>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="px-2 py-1 font-lg shadow border border-block"
          />
        </div>
      </div>

      <table className="w-full border-separate relative text-center">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="bg-gray-100 border-b w-full text-base font-semibold cursor-pointer" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  {...{
                    key: header.id,
                    colSpan: header.colSpan,
                    style: {
                      width: header.column.columnDef.meta?.size,
                    },
                    onClick: header.column.getToggleSortingHandler(),
                  }}>
                  <div className="py-4">
                    <span>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          t(header.column.columnDef.header),
                          header.getContext(),
                        )}
                    </span>
                    {{
                      asc: <FaChevronUp />,
                      desc: <FaChevronDown />,
                    }[header.column.getIsSorted()] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <tr key={row.id} className={`${index % 2 && 'bg-gray-100 '}`}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {totalPages > 0 && (
        <div className="flex justify-between items-center">
          <div className="text-sm">
            <span>
              {t('Page Size Info', {
                current: table.getState().pagination.pageIndex + 1,
                total: totalPages,
              })}
            </span>
          </div>
          <div key={remountComponent}>
            <ReactPaginate
              previousLabel={t('Previous')}
              nextLabel={t('Next')}
              pageCount={totalPages}
              onPageChange={({ selected }) => {
                table.setPageIndex(selected);
              }}
              containerClassName={styles.pagination}
              initialPage={table.getState().pagination.pageIndex}
            />
          </div>
        </div>
      )}
    </div>
  );
};

import { useTranslation } from 'react-i18next';
import styles from '../EServices.module.scss';
import { SLA } from './sla.types';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

const dataEn: SLA[] = [
  {
    title: 'خدمة التراخيص',
    value: '30 يوم',
    id: '1',
  },
  {
    title: 'خدمة طلب استئجار المرافق',
    value: '60 يوم',
    id: '2',
  },
  {
    title: 'خدمة مواعيد السفن',
    value: 'فورياً',
    id: '3',
  },
  {
    title: 'خدمة الاستعلام عن الحاويات',
    value: 'فورياً',
    id: '4',
  },
  {
    title: 'خدمة فواتير أصحاب البضائع',
    value: 'فورياً',
    id: '5',
  },
  {
    title: 'خدمة تتبع المعاملات',
    value: 'فورياً',
    id: '6',
  },
  {
    title: 'الإحصاءات',
    value: 'فورياً',
    id: '7',
  },
  {
    title: 'خدمة المنافسات',
    value: 'فورياً',
    id: '8',
  },
  {
    title: 'خدمة المنقولات',
    value: '30 يوم',
    id: '9',
  },
];

const columnHelper = createColumnHelper<SLA>();

export const SLAComponent = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(() => [...dataEn]);

  const columns = [
    columnHelper.accessor('id', {
      header: () => <span> {t('SLA Id')}</span>,
      cell: (row) => {
        return <div>{Number(row.row.id) + 1}</div>;
      },
    }),
    columnHelper.accessor('title', {
      header: () => <span>{t('SLA title')}</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('value', {
      header: () => <span>{t('SLA Description')}</span>,
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <div className={`${styles.homeButtonWrapper}`}>
            <a href={'/'} className={`${styles.homeButton}`}>
              {t('Home')}
            </a>
            <div className={`${styles.arrow}`}>{'>'}</div>
            <a href={'/e-services'} className={`${styles.homeButton}`}>
              {t('Back to e-services')}
            </a>
          </div>
          <h2 className={`${styles.hTwoTitlePage}`}>{t('SLA')}</h2>
          <div className={styles.eServiceContent}></div>

          <div className={styles.serviceIntro}>
            <p>
              {t(
                'Standards of electronic services of the General Authority of Ports',
              )}
            </p>
            <table className={styles.tableColored}>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

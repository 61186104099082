import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import KeepMeInformed from 'components/home/KeepMeInformed';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import StatisticsComp from 'components/EServices/Statistics';
import PopUpEServices from 'components/EServices/PopUpEServices';

export default function Statistics() {
  return (
    <>
      <Navbar />
      {/* <PopUpEServices /> */}
      <StatisticsComp />
      {/* <KeepMeInformed /> */}
      <Information />
      <Subscribe />
      <DefaultFooter />
    </>
  );
}

import { useState, useEffect } from 'react';
import { FormControl, Alert } from '@mui/material';
import DatePicker from '../DatePicker';
import styles from './EServices.module.scss';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { useExternalContext } from 'contexts/ExternalContext';
import { Table } from 'components/Table';
import { Loader } from 'components/Loader';
import { DateObject } from 'react-multi-date-picker';
import { formatDateToTableFormat } from '../../utils/format.string';

const DATE_FORMAT = 'DD/MM/YYYY';

const VesselSearch = () => {
  const { t, i18n } = useTranslation();
  const [selectedType, setSelectedType] = useState('searchVesselSchedule');
  const { getLookups } = useExternalContext();
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);
  useEffect(() => {
    getLookups();
  }, []);

  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <div className={`${styles.homeButtonWrapper}`}>
            <a href={'/'} className={`${styles.homeButton}`}>
              {t('Home')}
            </a>
            <div className={`${styles.arrow}`}>{'>'}</div>
            <a
              href={'/e-services'}
              className={`${styles.homeButton}`}>
              {t('Back to e-services')}
            </a>
          </div>
          <h2 className={`${styles.hTwoTitlePage}`}>{t('Vessel Search')}</h2>
          <div className={styles.eServiceContent}>
            {/* <h3>{t('Ports - Vessel Search Port')}</h3> */}
            <p>
              {t(
                'This is an e-service which allows beneficiaries to enquire about the vessels’ arrival/departure schedule and status in all Saudi ports, as well as the authorized shipping agent of the vessel.',
              )}
            </p>

            <FormControl>
              <div
                className={styles.radioGroup}
                value={selectedType}
                onChange={(event) => {
                  setSelectedType(event.target.value);
                }}>
                <label className={styles.radioItem}>
                  <input
                    type="radio"
                    id=""
                    name="vesselSearch"
                    value="searchVesselSchedule"
                    defaultChecked
                  />
                  {t('Search Vessel Schedule')}
                </label>
                <label className={styles.radioItem}>
                  <input
                    type="radio"
                    id=""
                    name="vesselSearch"
                    value="searchAgents"
                  />
                  {t('Search Agents')}
                </label>
              </div>
            </FormControl>
            {selectedType === 'searchVesselSchedule' ? (
              <SearchVesselSchedule />
            ) : (
              <SearchAgents />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const columnHelper = createColumnHelper();

const vesselSearchColumns = [
  columnHelper.accessor('trafficType', {
    cell: (info) => info.getValue(),
    header: 'Status',
    meta: {
      size: '15%',
    },
  }),
  columnHelper.accessor('arrivalDate', {
    cell: (info) => formatDateToTableFormat(info.getValue()),
    header: 'Arrival Date',
    meta: {
      size: '10%',
    },
  }),
  columnHelper.accessor('departureDate', {
    cell: (info) => formatDateToTableFormat(info.getValue()),
    header: 'Departure Date',
    meta: {
      size: '10%',
    },
  }),
  columnHelper.accessor('agent', {
    cell: (info) => info.getValue(),
    header: 'Agent',
    meta: {
      size: '25%',
    },
  }),
  columnHelper.accessor('tonnage', {
    cell: (info) => info.getValue(),
    header: 'Tonnage',
    meta: {
      size: '8%',
    },
  }),
  columnHelper.accessor('vessel', {
    cell: (info) => info.getValue(),
    header: 'Vessel Name',
  }),
];

const SearchVesselSchedule = () => {
  const { t, i18n } = useTranslation();
  const {
    vesselSearch,
    vesselSearchData,
    apiLoading,
    getCaptcha,
    captchaData,
    lookupsData,
  } = useExternalContext();
  const [vesselName, setVesselName] = useState('');
  const [agentName, setAgentName] = useState('');
  const [status, setStatus] = useState('');
  const [port, setPort] = useState('');
  const [startDate, setStartDate] = useState(
    new DateObject().format(DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [showResults, setShowResults] = useState(false);

  const isEnglish = i18n.language === 'en';

  const searchVessel = async () => {
    setShowResults(false);
    if (port !== '' || startDate !== '') {
      const dateArray = startDate.split('/');
      const formattedDate = `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;

      await vesselSearch({
        AgentName: agentName,
        VesselName: vesselName,
        Status: status,
        Port: port,
        StartDate: formattedDate,
        EndDate: endDate,
        captcha,
      });
      setShowResults(true);
      getCaptcha();
      setCaptcha('');
    }
  };

  useEffect(() => {
    if (endDate) {
      const startDateObject = new DateObject({
        date: startDate,
        format: DATE_FORMAT,
      });
      const endDateObject = new DateObject({
        date: endDate,
        format: DATE_FORMAT,
      });
      if (endDateObject.valueOf() - startDateObject.valueOf() < 0) {
        setEndDate('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const firstPortTitle = lookupsData?.['vesselPorts']?.[0]?.title;
  useEffect(() => {
    if (!port && firstPortTitle) {
      setPort(firstPortTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstPortTitle]);
  return (
    <>
      {showResults && !vesselSearchData?.isSuccess && (
        <div className="pb-4">
          <Alert severity="error">
            {t(vesselSearchData?.error || 'Something went wrong')}
          </Alert>
        </div>
      )}
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          searchVessel();
        }}>
        <div className={`${styles.splitForm}`}>
          <div className="form-field">
            <label>{t('Vessel')}</label>

            <input
              type="text"
              placeholder={t('Enter Vessel Name')}
              onChange={(e) => setVesselName(e.target.value)}></input>
          </div>

          <div className="form-field">
            <label>{t('Agent')}</label>

            <input
              type="text"
              placeholder={t('Enter Agent Name')}
              onChange={(e) => setAgentName(e.target.value)}></input>
          </div>
        </div>

        <div className={`${styles.splitForm}`}>
          <div className="form-field">
            <label>
              {t('Port')}
              <span className="require">*</span>
            </label>

            <select onChange={(e) => setPort(e.target.value)} required>
              {lookupsData?.['vesselPorts']?.map((i) => (
                <option value={i.title} key={i.title}>
                  {isEnglish ? i.title : i.titleAr}
                </option>
              ))}
            </select>
          </div>
          <div className="form-field">
            <label>{t('Status')}</label>

            <select onChange={(e) => setStatus(e.target.value)}>
              {lookupsData?.['vesselTrafficTypes']?.map((i) => (
                <option value={i.id !== -1 ? i.title : ''} key={i.title}>
                  {isEnglish ? i.title : i.titleAr || i.title}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={`${styles.splitForm}`}>
          <div className="form-field">
            <label>
              {t('Start Date')}
              <span className="require">*</span>
            </label>
            <DatePicker
              value={startDate}
              onChange={(e) => setStartDate(e.toString())}
              format={DATE_FORMAT}
              required
            />
          </div>
          <div className="form-field">
            <label>{t('End Date')}</label>
            <DatePicker
              value={endDate}
              onChange={(e) => setEndDate(e.toString())}
              format={DATE_FORMAT}
              minDate={new DateObject({ date: startDate, format: DATE_FORMAT })}
            />
          </div>
        </div>

        <div className={`${styles.splitForm}`}>
          <div className="form-field">
            <label>
              {t('Captcha')}
              <span className="require">*</span>
            </label>
            <img
              src={captchaData}
              className={'pb-2'}
              alt="captcha"
              width={'150px'}
              height={'60px'}
            />
            <input
              type="text"
              placeholder={t('Enter Captcha')}
              value={captcha}
              onChange={(e) => setCaptcha(e.target.value)}
              required
            />
          </div>
          <div></div>
        </div>

        <div className={styles.submitBtn}>
          <button
            className="button-default disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={apiLoading}>
            {t('Submit')}
          </button>
        </div>
      </form>
      <br />
      {apiLoading && <Loader />}
      {showResults && vesselSearchData?.data && (
        <Table columns={vesselSearchColumns} data={vesselSearchData?.data} />
      )}
    </>
  );
};

const agentSearchColumns = [
  columnHelper.accessor('title', {
    cell: (info) => info.getValue(),
    header: 'Title',
    meta: {
      size: '10%',
    },
  }),
  columnHelper.accessor('company', {
    cell: (info) => info.getValue(),
    header: 'Company',
  }),
  columnHelper.accessor('address', {
    cell: (info) => info.getValue(),
    header: 'Address',
    meta: {
      size: '36%',
    },
  }),
  columnHelper.accessor('description', {
    cell: (info) => info.getValue(),
    header: 'Description',
    meta: {
      size: '36%',
    },
  }),
];

const SearchAgents = () => {
  const { t, i18n } = useTranslation();
  const {
    agentSearch,
    agentSearchData,
    apiLoading,
    getCaptcha,
    captchaData,
    lookupsData,
  } = useExternalContext();
  const [agentName, setAgentName] = useState('');
  const [port, setPort] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [showResults, setShowResults] = useState(false);

  const isEnglish = i18n.language === 'en';

  const searchAgents = async () => {
    setShowResults(false);

    if (port !== '') {
      await agentSearch({
        AgentName: agentName,
        Port: port,
        captcha,
      });
      setShowResults(true);
      getCaptcha();
      setCaptcha('');
    }
  };

  const firstPortTitle = lookupsData?.['vesselPorts']?.[0]?.title;
  useEffect(() => {
    if (!port && firstPortTitle) {
      setPort(firstPortTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstPortTitle]);

  return (
    <>
      {showResults && !agentSearchData?.isSuccess && (
        <div className="pb-4">
          <Alert severity="error">
            {t(agentSearchData?.error || 'Something went wrong')}
          </Alert>
        </div>
      )}
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          searchAgents();
        }}>
        <div className={`${styles.splitForm}`}>
          <div className="form-field">
            <label>
              {t('Port')}
              <span className="require">*</span>
            </label>
            <select onChange={(e) => setPort(e.target.value)} required>
              {lookupsData?.['vesselPorts']?.map((i) => (
                <option value={i.title} key={i.title}>
                  {isEnglish ? i.title : i.titleAr}
                </option>
              ))}
            </select>
          </div>

          <div className="form-field">
            <label>{t('Agent')}</label>

            <input
              type="text"
              placeholder={t('Enter Agent Name')}
              onChange={(e) => setAgentName(e.target.value)}></input>
          </div>
        </div>

        <div className={`${styles.splitForm}`}>
          <div className="form-field">
            <label>
              {t('Captcha')}
              <span className="require">*</span>
            </label>
            <img
              src={captchaData}
              className={'pb-2'}
              alt="captcha"
              width={'150px'}
              height={'60px'}
            />
            <input
              type="text"
              placeholder={t('Enter Captcha')}
              value={captcha}
              onChange={(e) => setCaptcha(e.target.value)}
              required
            />
          </div>
          <div></div>
        </div>

        <div className={styles.submitBtn}>
          <button
            className="button-default disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={apiLoading}>
            {t('Submit')}
          </button>
        </div>
      </form>
      <br />
      {apiLoading && <Loader />}
      {showResults && agentSearchData?.data && (
        <Table columns={agentSearchColumns} data={agentSearchData?.data} />
      )}
    </>
  );
};

export default VesselSearch;

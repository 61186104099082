import styles from './EServices.module.scss';

import serviceImage from '../../assets/img/service-rental-flow.png';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFacilityContext } from 'contexts/FacilityContext';

const RentalFacilities = () => {
  const { facilities } = useFacilityContext();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);
  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <div className={`${styles.homeButtonWrapper}`}>
            <a href={'/'} className={`${styles.homeButton}`}>
              {t('Home')}
            </a>
            <div className={`${styles.arrow}`}>{'>'}</div>
            <a
              href={'/e-services'}
              className={`${styles.homeButton}`}>
              {t('Back to e-services')}
            </a>
          </div>
          <h2 className={`${styles.hTwoTitlePage}`}>{t('Request for rental of facilities')}</h2>
          <div className={styles.eServiceContent}>
            <div className={styles.serviceIntro}>
              <p>
                {t(
                  'This service allows beneficiaries to submit a request for rental of facilities in ports affiliated to Saudi Ports Authority, and to follow up on the status of previous requests without the need to head for the ports or headquarters.',
                )}
              </p>
              <p>
                <strong> {t('Steps for rental request')}:</strong>
              </p>
              <div className={styles.serviceStepsFive}>
                <div className={styles.step}>
                  <div className={styles.number}>{t('1')}</div>
                  <div className={styles.title}>
                    {t('Enter the Rental of Facility Service System')}
                  </div>
                </div>
                <div className={styles.step}>
                  <div className={styles.number}>{t('2')}</div>
                  <div className={styles.title}>{t('Press new request')}</div>
                </div>
                <div className={styles.step}>
                  <div className={styles.number}>{t('3')}</div>
                  <div className={styles.title}>{t('Fill in the application')}</div>
                </div>
                <div className={styles.step}>
                  <div className={styles.number}>{t('4')}</div>
                  <div className={styles.title}>{t('Attach documents')}</div>
                </div>
                <div className={styles.step}>
                  <div className={styles.number}>{t('5')}</div>
                  <div className={styles.title}>{t('Submit request')}</div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div>
              <table className={`${styles.table}`}>
                <thead>
                  <tr>
                    <td colSpan="3"> </td>
                    <td
                      colSpan="6"
                      className={`${styles.feeTableHeader} rounded-tl-lg  rtl:rounded-tr-lg`}>
                      {t('Rental Value (SAR/sqm)')}
                    </td>
                    <td
                      style={{ width: '25%' }}
                      rowSpan="2"
                      colSpan="3"
                      className={`${styles.feeTableHeader + ' ' + styles.feeTableLeftRadius
                        } rounded-tr-lg `}>
                      {t('Warehouse Rental Fee')}
                      <br />
                      {t('(SAR/sqm)')}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="3"
                      className={`${styles.feeTableHeaderLeft} rounded-tl-lg text-left rtl:rounded-tr-lg`}>
                      {t('PORTS')}
                    </td>
                    <td colSpan="2" className={`${styles.feeTableHeaderSmall}`}>
                      {t('Near')}
                    </td>
                    <td colSpan="2" className={`${styles.feeTableHeaderSmall}`}>
                      {t('Far')}
                    </td>
                    <td colSpan="2" className={`${styles.feeTableHeaderSmall}`}>
                      {t('Outside')}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {facilities &&
                    facilities.map((item) => {
                      return (
                        <tr>
                          <td colSpan="3" className={`${styles.feeTableCellLeft}`}>
                            <strong>{item.attributes.Port}</strong>
                          </td>
                          <td colSpan="2" className={`${styles.feeTableCellAuto}`}>
                            {item.attributes.Near}
                          </td>
                          <td colSpan="2" className={`${styles.feeTableCellAuto}`}>
                            {item.attributes.Far}
                          </td>
                          <td colSpan="2" className={`${styles.feeTableCellAuto}`}>
                            {item.attributes.Outside}
                          </td>
                          <td colSpan="3" className={`${styles.feeTableCellAuto}`}>
                            {item.attributes.RentalFee}
                          </td>
                        </tr>
                      );
                    })}
                  {/* <tr>
                    <td colSpan="3" className={`${styles.feeTableCellLeft}`}><strong>Jeddah Islamic Port</strong></td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>74</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>34</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>30</td>
                    <td colSpan="3" className={`${styles.feeTableCellAuto}`}>100</td>
                </tr>
                <tr>
                    <td colSpan="3" className={`${styles.feeTableCellLeft}`}><strong>King Abdulaziz Port in Dammam</strong></td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>74</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>34</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>30</td>
                    <td colSpan="3" className={`${styles.feeTableCellAuto}`}>100</td>
                </tr>
                <tr>
                    <td colSpan="3" className={`${styles.feeTableCellLeft}`}><strong>Jubail Commercial Port</strong></td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>39</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>21</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>-</td>
                    <td colSpan="3" className={`${styles.feeTableCellAuto}`}>60</td>
                </tr>
                <tr>
                    <td colSpan="3" className={`${styles.feeTableCellLeft}`}><strong>Yanbu Commercial Port</strong></td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>39</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>24</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>17</td>
                    <td colSpan="3" className={`${styles.feeTableCellAuto}`}>60</td>
                </tr>
                <tr>
                    <td colSpan="3" className={`${styles.feeTableCellLeft}`}><strong>Jazan Port</strong></td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>29</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>24</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>17</td>
                    <td colSpan="3" className={`${styles.feeTableCellAuto}`}>60</td>
                </tr>
                <tr>
                    <td colSpan="3" className={`${styles.feeTableCellLeft}`}><strong>King Fahad Industrial Port in Jubail</strong></td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>39</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>18</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>16</td>
                    <td colSpan="3" className={`${styles.feeTableCellAuto}`}>60</td>
                </tr>
                <tr>
                    <td colSpan="3" className={`${styles.feeTableCellLeft}`}><strong>King Fahad Industrial Port in Yanbu</strong></td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>14</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>4</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>2</td>
                    <td colSpan="3" className={`${styles.feeTableCellAuto}`}>60</td>
                </tr>
                <tr>
                    <td colSpan="3" className={`${styles.feeTableCellLeft}`}><strong>Ras Al-khair Port</strong></td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>14</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>4</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>2</td>
                    <td colSpan="3" className={`${styles.feeTableCellAuto}`}>60</td>
                </tr>
                <tr>
                    <td colSpan="3" className={`${styles.feeTableCellLeft} rounded-bl-lg`}><strong>Dhiba Port</strong></td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>29</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>16</td>
                    <td colSpan="2" className={`${styles.feeTableCellAuto}`}>10</td>
                    <td colSpan="3" className={`${styles.feeTableCellAuto} rounded-br-lg`}>60</td>
                </tr> */}
                </tbody>
              </table>
              <p className="mt-5">
                *{' '}
                {t(
                  'The value of leasing land for the activity of communication towers in ports, at a maximum price of 500 riyals per square meter',
                )}
                .
              </p>
              <br />
              <div className="flex flex-row-reverse">
                <a
                  className="button-default"
                  target="_blank"
                  rel="noreferrer"
                  href="https://eservices.mawani.gov.sa/RentSRVC/faces/RentSrvcAcc">
                  {t('Facility Rental Request Service')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default RentalFacilities;

import classNames from 'classnames';
import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef, useCallback } from 'react';
import { usePortContext } from '../../contexts/PortContext.js';
import SocialLinks from 'components/home/Information/SocialLinks';
import { useHistory } from 'react-router-dom';
import ChatBot from './chatbot';

export default function Footer() {
  const { t, i18n } = useTranslation();
  const { ports, GetAllPorts } = usePortContext();
  const history = useHistory();

  var localStoreVar = localStorage.getItem('locale');
  const isArabicLocale = localStoreVar == 'ar';

  const footerPadding = classNames({
    [styles.footerListPadding]: !isArabicLocale,
  });

  useEffect(() => {
    i18n.changeLanguage(localStoreVar);
    GetAllPorts(localStoreVar);
  }, [localStoreVar]);

  const handleOnClickUrl = (urlHash) => {
    history.push(`/about-us#${urlHash}`);
    history.go(0);
  };

  return (
    <>
      <footer className={styles.footer}>
        <ChatBot />
        <div className="container_max">
          <div className={styles.footerRow}>
            <div className={`${styles.footerGroup} ${footerPadding}`}>
              <a href="/about-us" className={styles.footerTitle}>
                {t('About Us')}
              </a>
              <ul>
                <li>
                  <a href="/about-us">{t('Introduction')}</a>
                </li>
                <li>
                  <a
                    className="cursor-pointer"
                    onClick={() => handleOnClickUrl('board')}>
                    {t('Board Of Directors')}
                  </a>
                </li>
                <li>
                  <a
                    className="cursor-pointer"
                    onClick={() => handleOnClickUrl('chart')}>
                    {t('Organizational Chart')}
                  </a>
                </li>
                <li>
                  <a
                    className="cursor-pointer"
                    onClick={() => handleOnClickUrl('services')}>
                    {t('Services & Capabilities')}
                  </a>
                </li>
              </ul>
            </div>
            <div className={`${styles.footerGroup} ${footerPadding}`}>
              <a href="/ports" className={styles.footerTitle}>
                {t('Saudi Ports')}
              </a>
              <ul>
                {ports.map((port, i) => (
                  <li key={i}>
                    <a
                      href={`/ports?port=${port.attributes.Port}`}
                      style={{ textTransform: 'capitalize' }}>
                      {port.attributes.Port}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.footerGroup}>
              <span className={styles.footerTitle}>{t('Port Services')}</span>
              <ul>
                <li>
                  <a href="/e-services">{t('E-Services')}</a>
                </li>
                <li>
                  <a href="/regulations">{t('Regulations & Circulars')}</a>
                </li>
              </ul>
            </div>
            <div className={styles.footerGroup}>
              <div className={styles.footerBadge}>
                <a href="https://raqmi.dga.gov.sa/platforms/platforms/b85eb8fb-3095-44a2-8d90-7b78928efa54/platform-license">
                  <img src="https://raqmi.dga.gov.sa/PlatformsApi/api/Attachments/a4805e42-d0b7-4f38-ae6a-ba37e03a4914" />
                </a>
              </div>
            </div>
          </div>
          <div className={'pb-14'}>
            <SocialLinks />
          </div>
        </div>

        <div className={styles.footerRibbon}>
          <div className="container_max">
            <ul>
              {/* <li>
                <a href="/privacy-policy">{t('Privacy Policy')}</a>
              </li>
              <li>.</li> */}
              <li>
                <a href="/safe-usage">{t('Safe Usage and Privacy Policy')}</a>
              </li>
              <li>.</li>
              <li>
                <a href="/conditions">{t('Terms and Conditions')}</a>
              </li>
              <li>.</li>
              <li>
                &copy; {t('Mawani Saudi Port Authority. All rights reserved')}
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

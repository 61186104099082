import styles from './EServices.module.scss';
import zip from "../../assets/img/eservices/zip.svg";

import zip2019 from "../../assets/downloads/statistics/Statistics_2019.zip"
import zip2018 from "../../assets/downloads/statistics/Statistics_2018.zip"
import zip2017 from "../../assets/downloads/statistics/statistics_2017_English.zip"
import zip2016 from "../../assets/downloads/statistics/Statistics_2016_English.zip"
import zip2015 from "../../assets/downloads/statistics/Statistics_2015.zip"
import zip2014 from "../../assets/downloads/statistics/Statistics_2014.zip"
import zip2013 from "../../assets/downloads/statistics/Statistics_2013.zip"
import zip2012 from "../../assets/downloads/statistics/Statistics_2012.zip"
import zip2011 from "../../assets/downloads/statistics/Statistics_2011.zip"
import zip2010 from "../../assets/downloads/statistics/Statistics_2010.zip"
import zip2009 from "../../assets/downloads/statistics/Statistics_2009.zip"
import zip2008 from "../../assets/downloads/statistics/Statistics_2008.zip"
import zip2007 from "../../assets/downloads/statistics/Statistics_2007.zip"
import zip2006 from "../../assets/downloads/statistics/Statistics_2006.zip"
import zip2005 from "../../assets/downloads/statistics/Statistics_2005.zip"
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const Statistics = () => {
    const { t, i18n } = useTranslation();
    const [pdfYear, setPdfYear] = useState(0);
    useEffect(() => {
        var localStoreVar = localStorage.getItem('locale');
        i18n.changeLanguage(localStoreVar);
    }, [])
    return (
        <>
            <div className={`${styles.bgHeaderBackground}`}></div>
            <div className="container_max">
                <div className="page_wrapper">
                    <div className={`${styles.homeButtonWrapper}`}>
                        <a href={'/'} className={`${styles.homeButton}`}>
                            {t('Home')}
                        </a>
                        <div className={`${styles.arrow}`}>{'>'}</div>
                        <a
                            href={'/e-services'}
                            className={`${styles.homeButton}`}>
                            {t('Back to e-services')}
                        </a>
                    </div>
                    <h2 className={`${styles.hTwoTitlePage}`}>{t("Statistics")}</h2>
                    <div className={styles.eServiceContent}>


                        {/* <p>
          This service allows owners of goods and beneficiaries to issue invoices from Saudi Ports Authority’s website through commercial ports invoices system. 
        </p> */}

                        <form onSubmit={ev => { return ev.preventDefault }}>
                            <div className={`${styles.splitSearch}`}>

                                {/* <div className="form-field">
              <label>{t("Port")}</label>
              <select>
                <option value="10">{t("Jeddah Islamic Port")}</option>
                <option value="20">{t("King Abdulaziz Port in Dammam")}</option>
                <option value="30">{t("King Fahad Industrial Port in Jubail")}</option>
                <option value="40">{t("Jubail Commercial Port")}</option>
                <option value="50">{t("King Fahad Industrial Port in Yanbu")}</option>
                <option value="60">{t("Yanbu Commercial Port")}</option>
                <option value="70">{t("Jazan Port")}</option>
                <option value="80">{t("Ras Al Khair Port")}</option>
              </select>
            </div> */}


                                <div className="form-field">
                                    <label>{t("Period")}</label>
                                    <select onChange={ev => { setPdfYear(ev.target.value) }}>
                                        <option value="0">{t("All")}</option>
                                        <option value="2019">{t("2019")}</option>
                                        <option value="2018">{t("2018")}</option>
                                        <option value="2017">{t("2017")}</option>
                                        <option value="2016">{t("2016")}</option>
                                        <option value="2015">{t("2015")}</option>
                                        <option value="2014">{t("2014")}</option>
                                        <option value="2013">{t("2013")}</option>
                                        <option value="2012">{t("2012")}</option>
                                        <option value="2011">{t("2011")}</option>
                                        <option value="2010">{t("2010")}</option>
                                        <option value="2009">{t("2009")}</option>
                                        <option value="2008">{t("2008")}</option>
                                        <option value="2007">{t("2007")}</option>
                                        <option value="2006">{t("2006")}</option>
                                        <option value="2005">{t("2005")}</option>
                                    </select>
                                </div>
                                {/* <div className={styles.submitBtn}>
          <button className="button-default">{t("Submit")}</button>
          </div> */}
                            </div>
                        </form>
                        <h3 className="pb-5 pt-5">{t("Statistics by year")}</h3>
                        <div className={styles.pdfSectionContainer}>
                            {pdfYear == "0" || pdfYear == "2019" ? (
                                <a className={styles.squareWrap} href={zip2019} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2019")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2018" ? (
                                <a className={styles.squareWrap} href={zip2018} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2018")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2017" ? (
                                <a className={styles.squareWrap} href={zip2017} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2017")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2016" ? (
                                <a className={styles.squareWrap} href={zip2016} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2016")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2015" ? (
                                <a className={styles.squareWrap} href={zip2015} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2015")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2014" ? (
                                <a className={styles.squareWrap} href={zip2014} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2014")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2013" ? (
                                <a className={styles.squareWrap} href={zip2013} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2013")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2012" ? (
                                <a className={styles.squareWrap} href={zip2012} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2012")}
                                    </p>
                                </a>) : ''}  {pdfYear == "0" || pdfYear == "2011" ? (
                                    <a className={styles.squareWrap} href={zip2011} download>
                                        <div className={styles.square}>
                                            <img src={zip} className={styles.zipIcon} />
                                        </div>
                                        <p className={styles.pdfFileNames}>
                                            {t("2011")}
                                        </p>
                                    </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2010" ? (
                                <a className={styles.squareWrap} href={zip2010} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2010")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2009" ? (
                                <a className={styles.squareWrap} href={zip2009} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2009")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2008" ? (
                                <a className={styles.squareWrap} href={zip2008} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2008")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2007" ? (
                                <a className={styles.squareWrap} href={zip2007} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2007")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2006" ? (
                                <a className={styles.squareWrap} href={zip2006} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2006")}
                                    </p>
                                </a>) : ''}

                            {pdfYear == "0" || pdfYear == "2005" ? (
                                <a className={styles.squareWrap} href={zip2005} download>
                                    <div className={styles.square}>
                                        <img src={zip} className={styles.zipIcon} />
                                    </div>
                                    <p className={styles.pdfFileNames}>
                                        {t("2005")}
                                    </p>
                                </a>) : ''}
                            {/* <a className={styles.squareWrap} href={zip2018} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2018")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2017} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2017")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2016} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2016")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2015} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2015")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2014} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2014")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2013} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2013")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2012} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2012")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2011} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2011")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2010} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2010")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2009} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2009")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2008} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2008")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2007} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2007")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2006} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2006")}
                </p>
            </a>
            <a className={styles.squareWrap} href={zip2005} download>
                <div className={styles.square}>
                    <img src={zip}  className={styles.zipIcon}/>
                </div>
                <p className={styles.pdfFileNames}>
                    {t("2005")}
                </p>
            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Statistics;

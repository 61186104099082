import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import { useTranslation } from 'react-i18next';
import styles from "./SafeUsage.module.scss";

function SafeUsageBlock() {
    const { t } = useTranslation();
    var localStore = localStorage.getItem("locale");
    if (localStore == null || localStore == undefined) {
        localStore = 'ar';
    }
    return (
        <div style={{}} className="container_max">
            <h2 className={{ fontWeight: 'bold', marginTop: '3rem' }}>
                {t("Safe Usage and Privacy Policy")}
            </h2>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Website Terms & Conditions")}
                </div>
                <div className={styles.description}>
                    {t("Welcome to the Saudi Ports Authority's (Mawani) website. By continuing to browse and use this website, you agree to abide by the following terms and conditions. These terms and conditions, along with our privacy policy, are subject to the governance of the relation between Mawani and the website visitor.")}
                </div>

            </div>
            <div className={styles.container}>
                <div className={styles.description}>
                    {t("The use of the Mawani's website is subject to the following conditions:")}
                </div>
                <ul className={styles.buletPoints}>
                    <li>
                        <div className={styles.description}>
                            {t("The website content is provided for general information purposes only, and it may be subject to change without notice.​")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Neither we nor any third parties provide any guarantee or warranty regarding the accuracy, timeliness, performance, completeness, or suitability of the information and materials offered on the website for any specific purpose. You acknowledge that such information and materials may contain errors, and we expressly disclaim any liability for such errors to the fullest extent permitted by law.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Your use of any information or materials on the website is at your own risk, and we shall not be liable for any damages or losses incurred. It is your responsibility to ensure that any products, services, or information obtained through this website meet your specific requirements.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("This website contains materials that are owned by or licensed to us. These materials include the design, layout, appearance, and graphics. Reproduction of these materials is prohibited except in accordance with the terms of the copyright notice, which forms an integral part of these terms and conditions.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Unauthorized use of the website may result in a claim for damages or constitute a criminal offense.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Occasionally, this website may include links to other websites for your convenience and to provide additional information. The inclusion of these links does not imply endorsement or responsibility for the content of the linked website(s).")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Prior written approval from Mawani is required to create a link to this website from another website or document.")}
                        </div>
                    </li>
                </ul>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Disclaimer")}
                </div>
                <div className={styles.description}>
                    {t("The information provided on this website is intended for general information purposes only. Although we strive to maintain the accuracy and validity of the information, we make no explicit or implicit representations or warranties regarding its completeness, accuracy, reliability, suitability, or availability, or regarding the information, products, services, or graphics related to the website.")}
                </div>
                <div className={styles.description}>
                    {t("Therefore, any reliance you place on such information is entirely at your own risk. We will not be liable for any loss or damage, including indirect or consequential loss or damage, or any loss of data or profits arising from or in connection with the use of the website.")}
                </div>
                <div className={styles.description}>
                    {t("Through this website, you may access other website that are not under the Mawani's control. We have no control over the nature, content, or availability of those websites. The presence of any links on our website does not necessarily imply our endorsement or support of the views expressed within those websites.")}
                </div>
                <div className={styles.description}>
                    {t("While we make every effort to keep the website running smoothly, Mawani cannot be held responsible and will not be subject to any claims for the temporary unavailability of the website due to technical problems beyond our control.​")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Privacy & Confidentiality Statement")}
                </div>
                <div className={styles.description}>
                    {t("We value your concerns regarding the privacy of your data. This policy has been developed to help you understand the nature of the information we collect when you visit our website and how we handle this personal data.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Browsing")}
                </div>
                <div className={styles.description}>
                    {t("Our website is not designed to collect your personal data from your computer while browsing. Any data provided by you will only be used with your knowledge and consent.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Internet Protocol (IP) Address")}
                </div>
                <div className={styles.description}>
                    {t("When you visit our website or any other website, the hosting server automatically records your Internet Protocol (IP) address, the date and time of your visit, the type of Internet browser you are using, and the URL of any website that referred you to this website.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Network Surveys")}
                </div>
                <div className={styles.description}>{t("We may conduct surveys on the network to collect specific data, including your views and feedback about our website. Your responses are greatly appreciated as they help us improve our website. You have full freedom to provide data related to your name and other details, as per your preference.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Links to Other Websites")}
                </div>
                <div className={styles.description}>{t("Our website may contain links to other websites or advertisements from third-party websites, such as Google AdSense. We are not responsible for the data collection methods employed by these websites. We recommend reviewing the privacy policies and contents of such websites before providing any personal information. We may engage third-party advertising companies to display ads when you visit our website. These companies may collect information about your visits to this and other websites, excluding personally identifiable information such as name, address, email address or telephone number, to provide advertisements about goods and services that may be of interest to you.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Disclosure of Information")}
                </div>
                <div className={styles.description}>{t("We are committed to maintaining the privacy and confidentiality of all personal data we collect. We will not disclose this information unless required by law or necessary to defend or protect the rights of this website or its beneficiaries.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Data Required for Transactions")}
                </div>
                <div className={styles.description}>{t("If we require any data from you, we will request it voluntarily. This information will help us fulfill your requests and enable us to contact you and fulfill your application whenever necessary. We will not sell your provided data to any third party for marketing purposes without your prior written consent unless it is used as part of collective data for statistical and research purposes, without including any information that can personally identify you.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("When You Contact Us")}
                </div>
                <div className={styles.description}>{t("All data provided by you will be treated as confidential. Forms submitted directly online require data that will help us improve our website. The information you provide will also be used to respond to your inquiries, comments, or requests by this website or any of its affiliated websites.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Disclosure of Information to Third Parties")}
                </div>
                <div className={styles.description}>{t("We will not sell, trade, rent, or disclose any information to any third party outside of this website or its affiliated websites. Information will only be disclosed if ordered to do so by any judicial or regulatory authority.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Amendments to the Information Confidentiality & Privacy Policy")}
                </div>
                <div className={styles.description}>{t("We reserve the right to amend the terms and conditions of the confidentiality and information privacy policy if necessary and appropriate. Any amendments will be implemented here, and you will be continuously notified of the data we have obtained, how we will use it, and to whom we will provide this data.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("How to submit a complaint or objection")}
                </div>
                <div className={styles.description}>
                    {t("As stipulated by the Law, and in a manner that would not conflict with Mawani's laws and regulations, we welcome all requests, inquiries, questions, and complaints regarding the privacy policy, or the rights of data subjects, by contacting the email:")}
                </div>
                <div className={styles.description}>{t("mawanicare@mawani.gov.sa")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Contact number")}
                </div>
                <div className={styles.description}>{t("Call us on: 199003")}
                </div>
                <div className={styles.description}>{t("Outside KSA")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("What are the Legal Bases for Collecting and Processing Your Personal Data and What is the Purpose of Collecting It?")}
                </div>
                <div className={styles.description}>
                    {t("The legal basis on which the Saudi Ports Authority “Mawani” relies to process personal data is to fulfill the regulatory obligations and requirements entrusted to it, in order to achieve any of the following purposes:​")}
                </div>
                <ul className={styles.buletPoints}>
                    <li>
                        <div className={styles.description}>
                            {t("Authenticating the identity of users and their contact information.​")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Enabling the Saudi Ports Authority “Mawani” to perform the tasks and responsibilities entrusted to it and provide services pursuant to the relevant laws and regulations.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Understanding the user's needs and striving to develop them, including but not limited to: receiving and processing inquiries, requests, and complaints.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Raising the level of service performance, developing it, improving the user experience, and ensuring the continuity of providing services with the required quality.")}
                        </div>
                    </li>
                </ul>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Protection of personal information:")}
                </div>
                <div className={styles.description}>{t("Personal information that the Commission may request or gather for any purpose other than to serve users and respond to their requests will not be used. The personal information collected by the Authority shall be limited to what the user decides to give by requesting publications or subscribing to the e-mail services of the Authority. In addition to the preceding, all personal information provided through certain functions of the Site, such as filling out the Contact Us form, will only be used to respond to requests, inquiries, and assistance to obtain the requested information.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Cookies")}
                </div>
                <div className={styles.description}>{t("The Authority may store cookies on your computer when you visit the Authority's website, as cookies are part of the data that uniquely identifies you as a user. It can also be used to improve your knowledge of the site and better understand the user base of this site. Most browsers are initially set up to accept cookies. You can also re-setup your browser to deny all cookies or to alert when cookies are sent. Please note that some services that are displayed on this portal may not work correctly if you refuse cookies. The TRA may link the information we store in cookies to any personally identifying information it provides on its website.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("What personal data is collected?")}
                </div>
                <div className={styles.description}>{t("The Saudi Ports Authority “Mawani” collects various types of personal data to provide its services and carry out its legal and regulatory functions. The minimum amount of personal data is collected to serve its purpose. The personal data collected may include, but is not limited to, general and sensitive personal data such as:")}
                </div>
                <ul className={styles.buletPoints}>
                    <li>
                        <div className={styles.description}>
                            {t("Name.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Contact details (e.g. phone number, address and email).")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("National ID Number and Iqama Number.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Date of Birth.")}
                        </div>
                    </li>
                </ul>
                <div className={styles.description}>{t("The Website is not designed to collect personal data from data subjects' computers while browsing. Any data provided shall only be used with the knowledge and consent of the data subject. Furthermore, data necessary to transmit data to users is collected and processed each time the Website is visited, including:")}
                </div>
                <ul className={styles.buletPoints}>
                    <li>
                        <div className={styles.description}>
                            {t("IP Address.​")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Browser type and version.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Operating system.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("The web page accessed.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            {t("Time of the server request.")}
                        </div>
                    </li>
                </ul>
                <div className={styles.description}>{t("The Saudi Ports Authority “Mawani” may conduct online surveys to collect specific data, including the data subject's opinions and comments about the website. The Saudi Ports Authority “Mawani” appreciates the responses of visitors to the website, as they help to improve the website. The visitor is completely free to provide data related to his name and other details, according to his preferences.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("How is Your Personal Data Collected?")}
                </div>
                <div className={styles.description}>{t("The Saudi Ports Authority “Mawani” collects personal data directly through the electronic forms you submit on the website, and indirectly through the use of (Cookies) technologies, and interconnection with other entities and in the cases that require processing requests related to the owner of the personal data and in accordance with the provisions of the relevant regulations and legislation.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("How Do We Store Your Personal Data?")}
                </div>
                <div className={styles.description}>{t("Your personal data is stored and kept securely at the headquarters of the Saudi Ports Authority “Mawani” or on its approved servers within the Kingdom of Saudi Arabia. The best security standards and practices related to ensuring data security and protection are applied, such as access control management processes, data classification and encryption, and regular security assessment procedures. The data is retained based on the time periods necessary to fulfill Saudi Ports Authority’s “Mawani” regulatory obligations and until the purpose of processing it is completed, after which it is destroyed in a secure manner that prevents the data from being accessed or recovered again, in accordance with the provisions of Article (18) of the Personal Data Protection Law.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("How is Your Personal Data Processed?")}
                </div>
                <div className={styles.description}>{t("The personal data collected from you is processed directly or indirectly to carry out the tasks and responsibilities assigned to the Saudi Ports Authority “Mawani” and in a manner that achieves the purposes specified in this policy. Personal data will only be processed by those authorized to do so in accordance with their competencies, and according to what is determined by the approved policies for this purpose.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("What are Your Rights Regarding the Processing of Your Personal Data?")}
                </div>
                <div className={styles.description}>{t("Under the Personal Data Protection Law, the owner of personal data has the following rights:")}
                </div>
                <ul className={styles.buletPoints}>
                    <li>
                        <div className={styles.description}>
                            <b>{t("The Right to Know: ")}</b>
                            {t("This includes informing you of the methods of collecting your personal data, the legal basis for collecting it, the mechanism for processing it, and all other details related to the processing of your personal data by clarifying them in this policy.​")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            <b>{t("The Right to Access and Obtain Your Personal Data: ")}</b>
                            {t("You have the right to request access to your personal data and obtain a copy of it in a clear, readable format that matches the content of the records - whenever technically possible - in accordance with what is determined by the relevant laws and regulations, without prejudice to the restrictions and exceptions contained therein, and taking into account any periods imposed by the Saudi Ports Authority “Mawani” to exercise or restrict this right.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            <b>{t("The Right to Request Correction of Your Personal Data: ")}</b>
                            {t("You have the right to request correction of your personal data that you believe is inaccurate, incorrect, or incomplete.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            <b>{t("The Right to Request Destruction of Your Personal Data: ")}</b>
                            {t("You have the right to request the destruction of your personal data that is no longer needed, unless there is a legal basis that specifies a period for retaining personal data in accordance with the provisions of Article (18) of the Personal Data Protection Law.")}
                        </div>
                    </li>
                    <li>
                        <div className={styles.description}>
                            <b>{t("The Right to Withdraw Your Consent to the Processing of Your Personal Data: ")}</b>
                            {t("You have the right to withdraw your consent to the processing of your personal data, unless there are legitimate purposes or legal justifications that require otherwise.")}
                        </div>
                    </li>
                </ul>
                <div className={styles.description}>{t("To submit a request regarding your rights mentioned above, you can do so by accessing the link Data Sharing Request Form، You will receive a response within thirty (30) days from the date the complete request is received.")}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.title}>
                    {t("Finally")}
                </div>
                <div className={styles.description}>{t("Your concerns and concerns about data confidentiality and privacy are of utmost importance to us. We hope that this policy will address and fulfill those concerns.")}
                </div>
            </div>
        </div >
    );
}

export default function SafeUsage() {
    return (
        <>
            <Navbar />
            <SafeUsageBlock />
            <Information />
            <Subscribe />
            <DefaultFooter />
        </>
    );
}

import styles from './EServices.module.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Announcement from './Announcement/Announcement';

const NewInvoice = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    var localStoreVar = localStorage.getItem('locale');
    i18n.changeLanguage(localStoreVar);
  }, []);

  return (
    <>
      <div className={`${styles.bgHeaderBackground}`}></div>
      <div className="container_max">
        <div className="page_wrapper">
          <div className={`${styles.homeButtonWrapper}`}>
            <a href={'/'} className={`${styles.homeButton}`}>
              {t('Home')}
            </a>
            <div className={`${styles.arrow}`}>{'>'}</div>
            <a
              href={'/e-services'}
              className={`${styles.homeButton}`}>
              {t('Back to e-services')}
            </a>
          </div>
          <h2 className={`${styles.hTwoTitlePage}`}>{t('Invoices of owners of goods Service')}</h2>
          <div className={styles.eServiceContent}>

            <div className={styles.serviceIntro}>
              <p>
                {t(
                  'This service allows owners of goods and beneficiaries to issue invoices from Saudi Ports Authority’s website through commercial ports invoices system.',
                )}
              </p>

              {/*
        {i18n.language === 'ar' && (
          <div className={styles.announcement}>
            <img src={campaign} alt="Announcement" />
            <div>
              <div className={styles.title}>تنبيه</div>
              <div className={styles.description}>يوم الجمعة 21 يوليو,2023 من الساعة 1 مساء حتى 3  مساء
              </div>
            </div>
          </div>
        )*/}
              {/*remove at friday morning  */}
              <Announcement />
              <p>
                <strong>{t('Invoice issuance steps:')}</strong>
                <div className={styles.serviceStepsFive}>
                  <div className={styles.step}>
                    <div className={styles.number}>{t('1')}</div>
                    <div className={styles.title}>
                      {t('Access invoice service system')}
                    </div>
                  </div>
                  <div className={styles.step}>
                    <div className={styles.number}>{t('2')}</div>
                    <div className={styles.title}>
                      {t('Enter username and password')}
                    </div>
                  </div>
                  <div className={styles.step}>
                    <div className={styles.number}>{t('3')}</div>
                    <div className={styles.title}>
                      {t(
                        'Enquire about bills information by entering the numbers of the manifest and the bill',
                      )}
                    </div>
                  </div>
                  <div className={styles.step}>
                    <div className={styles.number}>{t('4')}</div>
                    <div className={styles.title}>
                      {t('Enter delivery permission number and print invoice')}
                    </div>
                  </div>
                  <div className={styles.step}>
                    <div className={styles.number}>{t('5')}</div>
                    <div className={styles.title}>{t('Pay bill through SADAD')}</div>
                  </div>
                </div>
                <br />
                <a
                  className="button-default"
                  href="https://eservices.mawani.gov.sa/brokers-billing/login"
                  rel="noreferrer"
                  target="_blank">
                  {t('Access to the service')}
                </a>
                <ul className="list-disc font-light pb-10 pt-5">
                  {/* <li>
            ​​To access the service directly, please click on the following
            link​{' '}
            <a
              href="https://spa.mawani.gov.sa/forms/frmservlet?config=jre_broker"
              target="_blank"
              rel="noreferrer"
              className="font-normal underline text-same text-[#2D6A80]">
              Service of invoices of owners of goods
            </a>
          </li> */}
                  <li>
                    <a
                      href="/uploads/Broksers_Billing_User_Manual_v3_7ce43639b9.pdf"
                      download
                      className="font-normal underline text-same text-[#2D6A80]">
                      {t('Services User Manual')}
                    </a>
                  </li>
                </ul>
              </p>
              <p>
                <ul>
                  <li>
                    {t(
                      'The General Ports Authority bills can be paid through the SADAD service or through (Mada-Visa-MasterCard) cards via the electronic payment service at the link ',
                    )}
                    <a
                      href="https://eservices.mawani.gov.sa/payment-gateway/"
                      target="_blank"
                      rel="noreferrer"
                      className="font-normal underline text-same text-[#2D6A80]">
                      {t('Electronic payment gateway.')}
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewInvoice;
